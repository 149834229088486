import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "el-upload__tip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    ref: "refUpload",
    limit: _ctx.limit,
    "on-preview": _ctx.onPreview,
    "on-exceed": _ctx.onExceed,
    "on-remove": (f, l) => _ctx.onRemoveFile(f, l),
    "on-progress": _ctx.onProgress,
    action: _ctx.action,
    multiple: "",
    "on-success": _ctx.onSuccess({}),
    accept: _ctx.accpet,
    "file-list": _ctx.fileList
  }, {
    tip: _withCtx(() => [
      (_ctx.showTip)
        ? (_openBlock(), _createBlock("div", _hoisted_1, "请上传" + _toDisplayString(_ctx.accpet) + "文件", 1))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createVNode(_component_el_button, { type: "primary" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnText), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 3
  }, 8, ["limit", "on-preview", "on-exceed", "on-remove", "on-progress", "action", "on-success", "accept", "file-list"]))
}