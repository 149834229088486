
import roles from "@/types/roles.ts";
import Manage from "./manage.vue";

import { defineComponent, inject, ref, onMounted, reactive, toRefs } from "vue";
import {
  useQueryOfficialDocs,
  useOfficialDoc,
  DocComponents,
} from "@/network/officialDoc.ts";
import { dicLabel, docStatusDic, docOrgDic } from "@/network/lookUp.ts";
import Base from "@/views/layout/Base.vue";
export default defineComponent({
  components: { Base, ...DocComponents, Manage },
  setup() {
    const user: any = inject("user");

    const manageSetting = reactive({
      visible: false,
      id: null,
    });

    const filter = reactive({
      pageNum: 1,
      pageSize: 20,
      keyword: null,
    });
    const manage = (id) => {
      manageSetting.id = id;
      manageSetting.visible = true;
    };

    const showSetting = reactive({
      visible: false,
      id: null,
      approve: false,
    });
    const showDocContent = (id, approve = false) => {
      showSetting.visible = true;
      showSetting.id = id;
      showSetting.approve = approve;
    };
    const [isLoading, pagerData, query] = useQueryOfficialDocs(filter);
    const [isProcessing, , , , changeStatus, toggleUnique, remove] =
      useOfficialDoc();

    const doRemove = ({ id }) => {
      remove(id).then((p) => query());
    };
    const doChangeStatus = ({ id, status }) => {
      showSetting.visible = false;
      changeStatus(id, status).then((p) => query());
    };
    const doToggleUnique = ({ id }) => {
      toggleUnique(id).then((p) => query());
    };
    onMounted(() => {
      query();
    });

    return {
      dicLabel,
      docStatusDic,
      docOrgDic,
      user,
      roles,
      manage,
      manageSetting,
      showSetting,
      showDocContent,
      isLoading,
      pagerData,
      query,
      filter,
      isProcessing,
      doChangeStatus,
      doToggleUnique,
      doRemove,
      handleCurrentChange: (e) => {
        filter.pageNum = e;
        query();
      },
      handleSizeChange: (e) => {
        filter.pageSize = e;
        query();
      },
    };
  },
});
