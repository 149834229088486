
import fileupload from "@/components/fileupload";
import { ElMessage } from "element-plus";
import { rules } from "@/model/officialDocFormRules.ts";
import { useOfficialDoc } from "@/network/officialDoc.ts";
import { useSelectItemList, docOrgDic } from "@/network/lookUp.ts";
import {
  defineComponent,
  toRefs,
  ref,
  reactive,
  watch,
  Ref,
  PropType,
  computed,
} from "vue";
import settings from "@/settings";
export default defineComponent({
  components: {
    fileupload,
  },
  props: {
    setting: { type: Object },
  },
  setup(props, { emit }) {
    // const form = reactive({
    //   reduces: [],
    //   attachment: null,
    // });;

    const fileuploadSetting = reactive({
      limit: 1,
      accpet: "application/pdf",
      fileList: [],
    });

    const refForm = ref(null);
    const isFileUploading = ref(false);
    const { visible, id } = toRefs(props.setting as any);
    const [isLoading, form, find, save, submit] = useOfficialDoc(id);
    const now = new Date();

    const docYearDic = ref([]);
    for (let i = 0; i < 10; i++) {
      docYearDic.value.push((now.getFullYear() - i).toString());
    }
    const docTypeDic = useSelectItemList("OfficialDocType");
    const docCategoryDic = useSelectItemList("OfficialDocCategory");
    // const docOrgDic = ref([{labe:"",}]);

    watch(visible, (v) => {
      if (v) {
        find().then((p) => {
          setTimeout(() => refForm.value.clearValidate(), 1);
          if (form.filePath) {
            fileuploadSetting.fileList = [
              {
                url: form.filePath,
                name: form.fileName,
              },
            ];
          }
        });
      } else {
        fileuploadSetting.fileList = [];
      }
    });

    const onRemoveAttach = (res) => {
      form.filePath = null;
      form.fileName = null;
    };
    const onUploadAttach = ({ url, name }) => {
      form.filePath = url;
      form.fileName = name;
      isFileUploading.value = false;
    };

    const doSave = (changeEmit = true) => {
      return new Promise((resolve, reject) => {
        refForm.value.validate((valid) => {
          if (valid) {
            form.doc_Compile_No = `${
              docTypeDic.value.find((p) => p.value == form.doc_Type).label
            }﹝${form.doc_Year}﹞${form.doc_Serial_No}号`;
            form.Approved = 1;
            save().then(() => {
              resolve("success");
              visible.value = false;
              if (changeEmit) emit("change");
            });
          } else {
            reject("error");
          }
        });
      });
    };

    return {
      docOrgDic,
      docCategoryDic,
      docYearDic,
      docTypeDic,
      fileuploadSetting,
      onRemoveAttach,
      onUploadAttach,
      refForm,
      isLoading,
      action: settings.uploadAction,
      visible,
      form,
      doSave,
      rules,
      isFileUploading,
    };
  },
});
